import { useState, MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ForgotPwFormPropsType } from './types';
import { showNotification, IconTypeEnum } from '../../../../state/Notification';
import { LoginStatesEnum } from '../../types';
import { cognitoResetPassword, showCognitoError, CognitoErrorSourceEnum } from '../../../../helpers/cognito';

export default function ForgotPwForm({ email, setEmail, password, setPassword, setLoginState }: ForgotPwFormPropsType) {
  const { t } = useTranslation('', { keyPrefix: 'forgot-password' });
  const { t: cognitoT } = useTranslation('', { keyPrefix: 'cognito-errors' });
  const dispatch = useDispatch();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [oneTimeCode, setOneTimeCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  function handleResetPassword(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    const originButton = submitButtonRef?.current;
    if (originButton) {
      originButton.disabled = true;
    }

    cognitoResetPassword(email, oneTimeCode, password)
      .then(() => {
        dispatch(
          showNotification({
            header: t('reset-success.header'),
            details: t('reset-success.details'),
            iconType: IconTypeEnum.Info,
            buttonText: t('reset-success.button-text'),
          })
        );

        setLoginState(LoginStatesEnum.Login);
      })
      .catch((error) => {
        showCognitoError(error, CognitoErrorSourceEnum.FORGOT_PASSWORD, dispatch, cognitoT);
      })
      .finally(() => {
        if (originButton) {
          originButton.disabled = false;
        }
      });
  }

  return (
    <>
      <form onSubmit={(event: any) => handleResetPassword(event)} className='form container flex flex-col items-center w-2/5 gap-y-16'>
        <div className='form-header flex flex-col items-center'>
          <h2 className='text-4xl font-bold'>{t('main-header')}</h2>
          <span className='mt-2 text-base font-medium text-gray-main'>{t('second-header')}</span>
        </div>
        <div id='input-wrapper' className='w-full space-y-4'>
          <input
            type='email'
            id='login-email'
            minLength={3}
            placeholder={t('email-placeholder')}
            className='w-full py-2 px-4 bg-zinc-50 placeholder:text-gray-main'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
          <input
            type='text'
            id='one-time-code'
            minLength={3}
            placeholder={t('one-time-code-placeholder')}
            className='w-full py-2 px-4 bg-zinc-50 placeholder:text-gray-main'
            value={oneTimeCode}
            onChange={(event) => {
              setOneTimeCode(event.target.value);
            }}
            required
          />
          <div className='container flex flex-col items-center  w-full'>
            <label className='relative block w-full z-0'>
              <input
                type={showPassword ? 'text' : 'password'}
                id='login-password'
                minLength={3}
                placeholder={t('password-placeholder')}
                className='w-full py-2 pl-4 pr-10 bg-zinc-50 placeholder:text-gray-main'
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                required
              />
              <div
                className='absolute inset-y-0 right-0 flex items-center pr-3 hover:cursor-pointer'
                onClick={() => {
                  setShowPassword((showPassword) => !showPassword);
                }}
              >
                <img src={`/assets/login/${showPassword ? 'hide' : 'show'}_password.svg`} alt='show password' />
              </div>
            </label>
          </div>
        </div>
        <div id='submit-wrapper' className='w-full flex flex-col items-center'>
          <button
            type='submit'
            ref={submitButtonRef}
            className='flex justify-center py-3 w-full rounded-md bg-purple-dark text-white disabled:bg-purple-faded'
          >
            {t('submit-button')}
          </button>
        </div>
      </form>
    </>
  );
}
