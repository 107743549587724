import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Footer, { FooterLinkType } from './Footer';

export default function WithFooter() {
  const { t } = useTranslation('', { keyPrefix: 'footer' });

  const [links] = useState<FooterLinkType[]>([
    {
      name: t('links.privacy-policy'),
      link: 'https://www.sweetch.com/privacy-policy',
    },
    {
      name: t('links.terms-of-use'),
      link: 'https://www.sweetch.com/terms-of-use',
    },
  ]);

  return (
    <div>
      <Outlet />
      <Footer links={links} />
    </div>
  );
}
