import { iconTypeToFileName } from './helpers';
import { NotificationPropsType } from './types';

export default function Notification({ iconType, header, details, buttonText, onClose }: NotificationPropsType) {
  const iconFilename = iconTypeToFileName(iconType);

  return (
    <div id='notification-modal-wrapper' className='absolute flex justify-center inset-0 bg-black/80 z-50'>
      <div className='flex items-center w-full max-w-md h-full opacity-100'>
        <div className='bg-white rounded-lg shadow py-12 px-16'>
          <div className='flex flex-col items-center gap-5 w-max'>
            <img src={`/assets/notification/${iconFilename}`} alt={`${iconFilename}`} />
            <h1 className='font-bold text-3xl'>{header}</h1>
            <span className='whitespace-pre-line text-base text-gray-main'>{details}</span>
            <button type='button' className='text-black text-center text-bold' onClick={onClose}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
