import { createSlice } from '@reduxjs/toolkit';

import NotificationType, { IconTypeEnum } from './types';

const initialState = {
  shouldShow: false,
  iconType: IconTypeEnum.None,
  header: '',
  details: '',
  buttonText: '',
} as NotificationType;

export const notificationSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    showNotification(state, action) {
      state.shouldShow = true;
      state.iconType = Object.values(IconTypeEnum).includes(action.payload.iconType) ? action.payload.iconType : IconTypeEnum.None;
      state.header = action.payload.header ? action.payload.header : '';
      state.details = action.payload.details ? action.payload.details : '';
      state.buttonText = action.payload.buttonText ? action.payload.buttonText : '';
    },
    hideNotification() {
      return initialState;
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
