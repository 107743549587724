import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GlobalStateType from '../../state/types';

export default function WithAuth({ children }: { children: JSX.Element }) {
  const userState = useSelector((state: GlobalStateType) => ({
    isLoggedIn: state.user.isLoggedIn,
  }));

  if (!userState.isLoggedIn) {
    return <Navigate to='/login' replace />;
  }

  return children;
}
