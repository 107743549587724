import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation('', { keyPrefix: 'not-found' });

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='flex flex-col items-center gap-y-5 w-fit'>
        <div className='flex flex-row relative top-20'>
          <img src='/assets/404/404.svg' alt='4' className='w-full' />
          <img src='/assets/404/405.svg' alt='0' className='w-full' />
          <img src='/assets/404/406.svg' alt='4' className='w-full' />
        </div>
        <img src='/assets/404/sad_gang.svg' alt='sad gang' className='relative w-full' />
        <div className='flex flex-col items-center gap-y-2'>
          <h1 className='font-bold text-3xl'>{t('main-message')}</h1>
          <span className='text-base text-gray-main'>{t('sub-message')}</span>
        </div>
        <button
          className='px-10 py-2 rounded text-purple-dark font-bold text-base bg-purple-bg hover:bg-purple-faded focus:bg-purple-light'
          type='button'
          onClick={() => navigate(-1)}
        >
          {t('button-text')}
        </button>
      </div>
    </div>
  );
}
