import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import './index.css';
import store from './state/store';
import App from './App';
import './i18n';
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import WithNotification from './hoc/WithNotification/WithNotification';
import WithTokenRefresh from './hoc/WithTokenRefresh/WithTokenRefresh';
import Spinner from './components/Spinner';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <ErrorBoundary>
            <WithNotification>
              <WithTokenRefresh>
                <App />
              </WithTokenRefresh>
            </WithNotification>
          </ErrorBoundary>
        </Suspense>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
);
