import { useSelector, useDispatch } from 'react-redux';

import GlobalStateType from '../../state/types';
import { hideNotification } from '../../state/Notification/notificationSlice';
import Notification from '../../components/Notification/Notification';

export default function WithNotification({ children }: { children: JSX.Element }) {
  const { shouldShow, ...notificationConf } = useSelector((state: GlobalStateType) => state.notification);

  const dispatch = useDispatch();

  function internalOnClose() {
    dispatch(hideNotification());
  }

  return (
    <>
      {shouldShow && <Notification onClose={internalOnClose} {...notificationConf} />}
      {children}
    </>
  );
}
