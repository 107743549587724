import { ItemPropsType } from './types';

export default function Item({ itemConf }: ItemPropsType) {
  const { iconSrc, iconName, name, isSelected, onClick } = itemConf;

  let selectedBorder = '';
  let imgActiveClasses = '';
  let buttonActiveClasses = '';
  if (isSelected) {
    const transition = 'transition ease-linear duration-500';
    selectedBorder = `${transition} border-r-2 border-r-purple-dark`;
    imgActiveClasses = `${transition} bg-purple-faded rounded-full`;
    buttonActiveClasses = `${transition} text-purple-dark font-bold`;
  }

  return (
    <div
      className={`container flex flex-row max-h-screen text-base text-gray-main hover:text-purple-light ${selectedBorder}`}
      onClick={onClick}
    >
      <div id='item-align-wrapper' className='flex mx-3 space-x-2'>
        <div id='img-wrapper' className={`w-8 h-8 p-2 flex content-center justify-center ${imgActiveClasses}`}>
          <img src={iconSrc} alt={iconName} />
        </div>
        <button className={`${buttonActiveClasses}`}>
          <span className=' whitespace-nowrap'>{name}</span>
        </button>
      </div>
    </div>
  );
}
