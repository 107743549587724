import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';

import GlobalStateType from '../../state/types';
import awsExport from '../../aws-exports';
import { refreshToken } from '../../state/User';
import { SECONDS_TO_MILLI } from '../../consts';

// 1 sec * 60 * 10 = 10 min
const REFRESH_TIMER = 1000 * 60 * 10;

export default function WithTokenRefresh({ children }: { children: JSX.Element }) {
  const userState = useSelector((state: GlobalStateType) => ({
    isLoggeIn: state.user.isLoggedIn,
  }));
  Amplify.configure(awsExport);

  const dispatch = useDispatch();

  useEffect(() => {
    const refreshInterval = setInterval(refreshUserToken, REFRESH_TIMER);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [userState.isLoggeIn]);

  function refreshUserToken() {
    if (!userState.isLoggeIn) {
      return;
    }

    const refreshPromises = [Auth.currentAuthenticatedUser(), Auth.currentSession()];
    Promise.all(refreshPromises).then(([cognitoUser, currentSession]) => {
      cognitoUser.refreshSession(currentSession.refreshToken, (error: any, session: any) => {
        if (error) {
          console.log(error);
          return;
        }

        dispatch(
          refreshToken({
            jwtToken: session.idToken.jwtToken,
            expiryMilli: session.idToken.payload.exp * SECONDS_TO_MILLI,
            refreshToken: session.refreshToken.token,
          })
        );
      });
    });
  }

  return children;
}
