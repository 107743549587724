import { ProfileButtonPropsType } from './types';
//  focus:scale-125 focus:border-purple-dark
export default function ProfileButton({ isInFocus, onClick }: ProfileButtonPropsType) {
  const inFocusClass = isInFocus ? 'scale-125 border-purple-dark' : '';
  return (
    <button
      type='button'
      className={`max-w-thumbnail max-h-thumbnail p-1 rounded-full bg-zinc-50 border-2 border-zinc-300 transition ease-in-out delay-75 hover:scale-125 hover:border-purple-dark ${inFocusClass}`}
      onClick={onClick}
    >
      <img className='rounded-full' src='/assets/profile/default_avatar.svg' alt='user avatar' />
    </button>
  );
}
