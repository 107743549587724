import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink, ApolloClient, DefaultOptions, InMemoryCache, ApolloClientOptions } from '@apollo/react-hooks';

import { LinkConfType, ApolloClientOtherOptions } from './index';
import store from '../state/store';

const networkOnlyConf: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

function apolloClientFactory(linkConf: LinkConfType, otherOptions = {} as ApolloClientOtherOptions) {
  const apolloLink = ApolloLink.from([
    createAuthLink(linkConf) as unknown as ApolloLink,
    createSubscriptionHandshakeLink(linkConf) as unknown as ApolloLink,
  ]);

  const apolloClientConf: ApolloClientOptions<any> = {
    link: apolloLink,
    cache: new InMemoryCache(),
    name: process.env.REACT_APP_NAME,
  };

  if (otherOptions.isNetworkOnly) {
    apolloClientConf.defaultOptions = networkOnlyConf;
  }

  return new ApolloClient(apolloClientConf);
}

export function dashboardsApolloClientFactory() {
  const state = store.getState();

  return apolloClientFactory(
    {
      url: process.env.REACT_APP_GRAPHQL_API_URL,
      region: process.env.REACT_APP_GRAPHQL_REGION,
      auth: {
        type: process.env.REACT_APP_GRAPHQL_AUTH_TYPE,
        jwtToken: state.user.jwtToken,
      } as AuthOptions,
    },
    { isNetworkOnly: false }
  );
}
