import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  login,
  JWT_TOKEN_KEY,
  EMAIL_KEY,
  LocalStorageJwtTokenType,
  LocalStorageEmailType,
  setUserToken,
  setUserCredentials,
} from '../../state/User';

import { LoginStatesEnum, COOKIE_EXPIRY } from './types';
import LoginForm from './Forms/LoginForm/LoginForm';
import ForgotPwForm from './Forms/ForgotPwForm/ForgotPwForm';
import SetupMFAForm from './Forms/SetupMFAForm/SetupMFAForm';
import { useCookie } from '../../hooks/cookies';
import { showNotification, IconTypeEnum } from '../../state/Notification';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('', { keyPrefix: 'login' });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [loginState, setLoginState] = useState(LoginStatesEnum.Login);
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const [allowedCookies, setAllowedCookies] = useCookie('allowed-cookies');

  // check if user allowed cookies
  useEffect(() => {
    if (!allowedCookies) {
      dispatch(
        showNotification({
          header: t('allow-cookies.header'),
          details: t('allow-cookies.details'),
          iconType: IconTypeEnum.Info,
          buttonText: t('allow-cookies.button-text'),
        })
      );

      setAllowedCookies('true', COOKIE_EXPIRY);
    }
  }, []);

  // login if jwt token is valid
  useEffect(() => {
    const jwtTokenConfStr = localStorage.getItem(JWT_TOKEN_KEY);
    if (jwtTokenConfStr) {
      const jwtTokenConf: LocalStorageJwtTokenType = JSON.parse(jwtTokenConfStr);
      if (jwtTokenConf.expiryMilli > new Date().getTime()) {
        dispatch(login({ isLoggedIn: true }));
        dispatch(setUserToken(jwtTokenConf));
        dispatch(setUserCredentials(JSON.parse(localStorage.getItem(EMAIL_KEY) || '{}')));
        navigate('/dashboards');
      }
    }

    const credentialsStr = localStorage.getItem(EMAIL_KEY);
    if (credentialsStr) {
      const credentials: LocalStorageEmailType = JSON.parse(credentialsStr);
      setEmail(credentials.email);
    }
  }, []);

  function getCurrentForm(loginState: LoginStatesEnum, params: any) {
    switch (loginState) {
      case LoginStatesEnum.Login:
        return <LoginForm {...params} />;
      case LoginStatesEnum.ForgotPw:
        return <ForgotPwForm {...params} />;
      case LoginStatesEnum.SetupMFA:
        return <SetupMFAForm {...params} />;
    }
  }

  return (
    <div className='flex flex-row max-h-screen'>
      <div className='flex items-center w-1/2 h-screen bg-gradient-to-r from-purple-light to-purple-dark'>
        <img src='/assets/login/sweetch_logo.png' alt='sweetch logo' className='w-full' />
      </div>
      <div className='flex flex-col items-center justify-center w-1/2 h-screen'>
        {getCurrentForm(loginState, {
          email,
          setEmail,
          password,
          setPassword,
          setLoginState,
          cognitoUser,
          setCognitoUser,
          isRememberMe,
          setIsRememberMe,
        })}
      </div>
    </div>
  );
}
