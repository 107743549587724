import Item from './Item';
import { SidebarPropsType, ItemType } from './types';

export default function Sidebar({ items, setItems, iconsPath }: SidebarPropsType) {
  function updateSelectedItem(itemId: string) {
    const updatedItems = items.map((item: ItemType) => {
      const isSelected = item.id === itemId;
      const iconColor = isSelected ? 'purple' : 'gray';

      return {
        ...item,
        isSelected,
        iconSrc: `${iconsPath}${iconColor}_${item.iconName}.svg`,
      };
    });

    setItems(updatedItems);
  }

  return (
    <div className='w-80 h-screen bg-white'>
      <aside id='dashboards-nav' className='w-full'>
        <div className='overflow-y-auto py-4'>
          <ul className='space-y-2'>
            <div id='logo-wrapper' className='flex justify-center mt-12 mx-12 mb-28'>
              <img src='assets/sweetch_logo.png' alt='sweetch logo' />
            </div>
            {items.map((item: ItemType) => (
              <Item
                key={item.id}
                itemConf={{
                  ...item,
                  isSelected: item.isSelected ? item.isSelected : false,
                  onClick: (event) => {
                    if (item.onClick) {
                      item.onClick(event);
                    }
                    updateSelectedItem(item.id);
                  },
                }}
              />
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
}
