import { FooterPropsType, FooterLinkType } from './types';

export default function Footer({ links }: FooterPropsType) {
  return (
    <footer className='flex flex-col items-center py-1 w-full h-fit text-white bg-blue-dark'>
      {links.map(({ link, name }: FooterLinkType) => (
        <a href={link} target='_blank' rel='noreferrer' key={name}>
          {name}
        </a>
      ))}
    </footer>
  );
}
