import { useState, MouseEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LoginFormPropsType } from './types';
import { LoginStatesEnum } from '../../types';
import { showNotification, IconTypeEnum } from '../../../../state/Notification';
import { logOut, REMEMBER_ME_KEY, LocalStorageIsRememberMeType } from '../../../../state/User';
import {
  cognitoLogin,
  cognitoSendChallenge,
  signInUser,
  sendForgotPasswordEmail,
  showCognitoError,
  cognitoSetNewPassword,
  CognitoChallengeEnum,
  CognitoErrorSourceEnum,
} from '../../../../helpers/cognito';

export default function LoginForm({
  email,
  setEmail,
  password,
  setPassword,
  isRememberMe,
  setIsRememberMe,
  setLoginState,
  cognitoUser,
  setCognitoUser,
}: LoginFormPropsType) {
  const { t } = useTranslation('', { keyPrefix: 'login' });
  const { t: cognitoT } = useTranslation('', { keyPrefix: 'cognito-errors' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [challenge, setChallenge] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [challengeType, setChallengeType] = useState<CognitoChallengeEnum>(CognitoChallengeEnum.NONE);
  const [submitText, setSubmitText] = useState('');
  const [handleSubmit, setHandleSubmit] = useState<any>(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const rememberMeConf = localStorage.getItem(REMEMBER_ME_KEY);
    if (rememberMeConf) {
      const isRememberMe: LocalStorageIsRememberMeType = JSON.parse(rememberMeConf);
      setIsRememberMe(isRememberMe.isRememberMe);
    }
  }, []);

  useEffect(() => {
    switch (challengeType) {
      case CognitoChallengeEnum.SOFTWARE_TOKEN_MFA:
        setSubmitText(t('button-text.submit-challenge'));
        setHandleSubmit(() => handleSubmitChallenge);
        break;
      case CognitoChallengeEnum.NEW_PASSWORD_REQUIRED:
        setSubmitText(t('button-text.set-new-password'));
        setHandleSubmit(() => handleSetNewPassword);
        break;
      case CognitoChallengeEnum.MFA_SETUP:
        setLoginState(LoginStatesEnum.SetupMFA);
        break;
      default:
        setSubmitText(t('button-text.log-in'));
        setHandleSubmit(() => handleLogin);
    }
  }, [challengeType, email, password, challenge, newPassword]);

  function handleLogin(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    const originButton = submitButtonRef?.current;
    if (originButton) {
      originButton.disabled = true;
    }

    cognitoLogin(email, password)
      .then((cognitoUser) => {
        if (cognitoUser.signInUserSession) {
          signInUser(cognitoUser, isRememberMe, dispatch);
          navigate('/dashboards');
        } else {
          setCognitoUser(cognitoUser);
          setChallengeType(cognitoUser.challengeName);
        }
      })
      .catch((error) => {
        dispatch(logOut());
        showCognitoError(error, CognitoErrorSourceEnum.LOGIN, dispatch, cognitoT);
      })
      .finally(() => {
        if (originButton) {
          originButton.disabled = false;
        }
      });
  }

  function handleSubmitChallenge(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    const originButton = submitButtonRef?.current;
    if (originButton) {
      originButton.disabled = true;
    }

    cognitoSendChallenge(cognitoUser, challenge, challengeType)
      .then((cognitoUser) => {
        signInUser(cognitoUser, isRememberMe, dispatch);
        navigate('/dashboards');
      })
      .catch((error) => {
        dispatch(logOut());
        showCognitoError(error, CognitoErrorSourceEnum.LOGIN, dispatch, cognitoT);
      })
      .finally(() => {
        if (originButton) {
          originButton.disabled = false;
        }
      });
  }

  function handleForgotPassword() {
    if (!email) {
      dispatch(
        showNotification({
          header: t('email-required.header'),
          details: t('email-required.details'),
          iconType: IconTypeEnum.Error,
          buttonText: t('email-required.button-text'),
        })
      );
      return;
    }

    sendForgotPasswordEmail(email)
      .then(() => {
        dispatch(
          showNotification({
            header: t('forgot-password.header'),
            details: t('forgot-password.details'),
            iconType: IconTypeEnum.Info,
            buttonText: t('forgot-password.button-text'),
          })
        );
        setLoginState(LoginStatesEnum.ForgotPw);
      })
      .catch((error) => {
        showCognitoError(error, CognitoErrorSourceEnum.LOGIN, dispatch, cognitoT);
        setChallengeType(CognitoChallengeEnum.NONE);
      });
  }

  function handleSetNewPassword(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    const originButton = submitButtonRef?.current;
    if (originButton) {
      originButton.disabled = true;
    }

    cognitoSetNewPassword(cognitoUser, newPassword)
      .then((cognitoUser) => {
        if (!cognitoUser.challengeName) {
          signInUser(cognitoUser, isRememberMe, dispatch);
          navigate('/dashboards');
        } else {
          setCognitoUser(cognitoUser);
          setChallengeType(cognitoUser.challengeName);
        }
      })
      .catch((error) => {
        dispatch(logOut());
        showCognitoError(error, CognitoErrorSourceEnum.LOGIN, dispatch, cognitoT);
        setChallengeType(CognitoChallengeEnum.NONE);
      })
      .finally(() => {
        if (originButton) {
          originButton.disabled = false;
        }
      });
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='form container flex flex-col items-center w-2/5 gap-y-16'>
        <div className='form-header flex flex-col items-center'>
          <h2 className='text-4xl font-bold'>{t('main-header')}</h2>
          <span className='mt-2 text-base font-medium text-gray-main'>{t('second-header')}</span>
        </div>
        <div id='input-wrapper' className='w-full space-y-4'>
          <input
            type='email'
            id='login-email'
            minLength={3}
            placeholder={t('email-placeholder')}
            className='w-full py-2 px-4 bg-zinc-50 placeholder:text-gray-main'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
          {challengeType === CognitoChallengeEnum.SOFTWARE_TOKEN_MFA && (
            <input
              type='text'
              id='challenge-verification'
              minLength={3}
              placeholder={t('challenge-verification-placeholder')}
              className='w-full py-2 px-4 bg-zinc-50 placeholder:text-gray-main'
              value={challenge}
              onChange={(event) => {
                setChallenge(event.target.value);
              }}
              required
            />
          )}
          {challengeType === CognitoChallengeEnum.NEW_PASSWORD_REQUIRED && (
            <label className='relative block w-full z-0'>
              <input
                type={showNewPassword ? 'text' : 'password'}
                id='new-password'
                minLength={3}
                placeholder={t('new-password-placeholder')}
                className='w-full py-2 pl-4 pr-10 bg-zinc-50 placeholder:text-gray-main'
                value={newPassword}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                required
              />
              <div
                className='absolute inset-y-0 right-0 flex items-center pr-3 hover:cursor-pointer'
                onClick={() => {
                  setShowNewPassword((showNewPassword) => !showNewPassword);
                }}
              >
                <img src={`/assets/login/${showNewPassword ? 'hide' : 'show'}_password.svg`} alt='show password' />
              </div>
            </label>
          )}
          <div className='container flex flex-col items-center  w-full'>
            <label className='relative block w-full z-0'>
              <input
                type={showPassword ? 'text' : 'password'}
                id='login-password'
                minLength={3}
                placeholder={t('password-placeholder')}
                className='w-full py-2 pl-4 pr-10 bg-zinc-50 placeholder:text-gray-main'
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                required
              />
              <div
                className='absolute inset-y-0 right-0 flex items-center pr-3 hover:cursor-pointer'
                onClick={() => {
                  setShowPassword((showPassword) => !showPassword);
                }}
              >
                <img src={`/assets/login/${showPassword ? 'hide' : 'show'}_password.svg`} alt='show password' />
              </div>
            </label>
            <div className='container mt-1 flex justify-between text-sm'>
              <div id='remember-me-wrapper'>
                <input
                  type='checkbox'
                  id='remember-me'
                  name='remember-me'
                  checked={isRememberMe}
                  onChange={() => {
                    const newIsRememberMe = !isRememberMe;
                    setIsRememberMe(newIsRememberMe);
                  }}
                />
                <label htmlFor='remember-me' className='text-gray-main ml-2'>
                  {t('remember-me')}
                </label>
              </div>
              <button type='button' className='text-link-unvisited' onClick={handleForgotPassword}>
                {t('forgot-password-link')}
              </button>
            </div>
          </div>
        </div>
        <div id='login-wrapper' className='w-full flex flex-col items-center'>
          <button
            type='submit'
            ref={submitButtonRef}
            className='flex justify-center py-3 w-full rounded-md bg-purple-dark text-white disabled:bg-purple-faded'
          >
            {submitText}
          </button>
        </div>
      </form>
    </>
  );
}
