import { useState } from 'react';

import { useQuery, DocumentNode, TypedDocumentNode, QueryHookOptions, QueryResult, OperationVariables } from '@apollo/react-hooks';

import { dashboardsApolloClientFactory } from './index';

export function useDashboardsApolloClient() {
  const [dashboardsApolloClient] = useState(dashboardsApolloClientFactory());

  return dashboardsApolloClient;
}

export function useDashboardsQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const dashboardsApolloClient = useDashboardsApolloClient();

  const onErrorMessage = (error: any) => {
    console.log(error);
  };

  return useQuery(query, {
    ...options,
    onError: (error) => {
      if (options?.onError) {
        options?.onError(error);
      }
      onErrorMessage(error);
    },
    client: dashboardsApolloClient,
  });
}
