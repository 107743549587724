import { TopbarPropsType } from './types';
import ProfileMenu from './ProfileMenu';

export default function Topbar({ header }: TopbarPropsType) {
  return (
    <div className='w-full h-40 px-12'>
      <aside id='topbar-wrapper' className='flex items-center justify-between w-full h-full'>
        <h1 className='text-4xl font-bold'>{header}</h1>
        <ProfileMenu />
      </aside>
    </div>
  );
}
