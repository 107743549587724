import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/userSlice';
import notificationReducer from './Notification/notificationSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
