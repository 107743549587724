import { useState, MouseEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

import { SetupMFAFormPropsType } from './types';

import {
  showCognitoError,
  cognitoSetopTOTP,
  cognitoVerifyTotpToken,
  buildQrCodeStr,
  cognitoCurrentAuthenticatedUser,
  signInUser,
  CognitoErrorSourceEnum,
} from '../../../../helpers/cognito';

import { LoginStatesEnum } from '../../types';

export default function SetupMFAForm({ cognitoUser, setLoginState, isRememberMe }: SetupMFAFormPropsType) {
  const { t } = useTranslation('', { keyPrefix: 'setup-mfa' });
  const { t: cognitoT } = useTranslation('', { keyPrefix: 'cognito-errors' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [qrCode, setQrCode] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    getQrCode(cognitoUser);
  }, []);

  function getQrCode(cognitoUser: any) {
    cognitoSetopTOTP(cognitoUser).then((code) => {
      setQrCode(buildQrCodeStr(cognitoUser, code));
    });
  }

  function handleSubmitCode(event: MouseEvent<HTMLElement>) {
    event.preventDefault();

    const originButton = submitButtonRef?.current;
    if (originButton) {
      originButton.disabled = true;
    }

    cognitoVerifyTotpToken(cognitoUser, code)
      .then(cognitoCurrentAuthenticatedUser)
      .then((cognitoUser) => {
        signInUser(cognitoUser, isRememberMe, dispatch);
        navigate('/dashboards');
      })
      .catch((error) => {
        showCognitoError(error, CognitoErrorSourceEnum.SETUP_MFA, dispatch, cognitoT);
        if (error.code === 'NotAuthorizedException') {
          setLoginState(LoginStatesEnum.Login);
        }
      })
      .finally(() => {
        if (originButton) {
          originButton.disabled = false;
        }
      });
  }

  return (
    <>
      <form onSubmit={(event: any) => handleSubmitCode(event)} className='form container flex flex-col items-center w-2/5 gap-y-16'>
        <div className='form-header flex flex-col items-center'>
          <h2 className='text-4xl font-bold'>{t('main-header')}</h2>
          <span className='mt-2 text-center text-base font-medium text-gray-main'>{t('second-header')}</span>
        </div>
        <div id='input-wrapper' className='flex items-center flex-col w-full space-y-4'>
          {qrCode.length > 0 && <QRCodeCanvas value={qrCode} />}
          <input
            type='text'
            id='login-code'
            minLength={3}
            placeholder={t('code-placeholder')}
            className='w-full py-2 px-4 bg-zinc-50 placeholder:text-gray-main'
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
            required
          />
        </div>
        <div id='submit-wrapper' className='w-full flex flex-col items-center'>
          <button
            type='submit'
            ref={submitButtonRef}
            className='flex justify-center py-3 w-full rounded-md bg-purple-dark text-white disabled:bg-purple-faded'
          >
            {t('submit-code')}
          </button>
        </div>
      </form>
    </>
  );
}
