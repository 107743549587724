import { IconTypeEnum } from '../../state/Notification/types';

export function iconTypeToFileName(iconType: IconTypeEnum) {
  switch (iconType) {
    case IconTypeEnum.Error:
      return 'red_exclamation_mark.png';
    case IconTypeEnum.Info:
      return 'gray_info.svg';
    default:
      return IconTypeEnum.None;
  }
}
