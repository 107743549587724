interface AwsAuthConfigType {
  region: string;
  userPoolWebClientId: string;
  userPoolId: string;
}

interface AwsExportsType {
  Auth: AwsAuthConfigType;
}

const awsExport: AwsExportsType = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  },
};

export default awsExport;
