export enum CognitoChallengeEnum {
  NONE = 'NONE',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  MFA_SETUP = 'MFA_SETUP',
}

export type CogniotoMFAChallengeType = 'SOFTWARE_TOKEN_MFA' | 'SMS_MFA';

export enum CognitoErrorSourceEnum {
  LOGIN = 'login',
  SETUP_MFA = 'setup-mfa',
  FORGOT_PASSWORD = 'forgot-password',
}
