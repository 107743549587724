import gql from 'graphql-tag';

export const GetUserDashboards = gql`
  query GetUserDashboards {
    GetUserDashboards {
      dashboards {
        dashboard_id
        name
        icon_name
        priority
      }
    }
  }
`;

export const GetDashboard = gql`
  query GetDashboard($dashboard_name: String!) {
    GetDashboard(dashboard_name: $dashboard_name) {
      dashboard {
        dashboard
      }
    }
  }
`;
