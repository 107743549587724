import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ProfileButton from './ProfileButton';
import { useComponentDimensions } from '../../../hooks/window';
import GlobalStateType from '../../../state/types';
import { useTranslation } from 'react-i18next';
import { logOut } from '../../../state/User';

export default function ProfileMenu() {
  const { targetRef: wrapperRef, dimensions } = useComponentDimensions();
  const modalRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('', { keyPrefix: 'profile' });
  const email = useSelector((state: GlobalStateType) => state.user.email);
  const dispatch = useDispatch();
  const [shouldShow, setShouldShow] = useState(false);

  function toggleShouldShow() {
    setShouldShow(!shouldShow);
  }

  const showingClass = shouldShow ? 'border-b-4 border-b-purple-dark' : '';

  return (
    <div ref={wrapperRef} id='profile-wrapper' className={`relative h-full flex items-center ${showingClass}`}>
      <ProfileButton isInFocus={shouldShow} onClick={toggleShouldShow} />

      {shouldShow && (
        <div
          id='profile-menu'
          ref={modalRef}
          className='absolute p-5 w-60 h-fit right-0 text-gray-500 bg-white rounded-lg shadow'
          style={{ top: dimensions.height }}
        >
          <div className='flex w-full justify-end'>
            <button
              type='button'
              className='flex items-center justify-center rounded-lg hover:bg-gray-100 h-5 w-5'
              onClick={toggleShouldShow}
            >
              <img src='assets/profile/close_menu.svg' alt='close' />
            </button>
          </div>
          <div className='flex flex-col items-center justify-center space-y-4'>
            <div className='flex flex-col items-center space-y-4 w-full'>
              <div
                id='avatar-wrapper'
                className='max-w-thumbnail-lg max-h-thumbnail-lg p-1 rounded-full bg-zinc-50 border-2 border-zinc-300'
              >
                <img className='rounded-full' src='/assets/profile/default_avatar.svg' alt='user avatar' />
              </div>
              <span className='w-full text-xl font-bold truncate' title={email}>
                {email}
              </span>
            </div>
            <hr className='w-full mx-2 border-gray-faded border-1' />
            <button
              type='button'
              className='flex w-full space-x-2'
              onClick={() => {
                dispatch(logOut());
              }}
            >
              <img src='/assets/profile/log_out.svg' alt='logout' />
              <span className='text-gray-mid'>{t('log-out')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
