import { Routes, Route } from 'react-router-dom';

import WithAuth from './hoc/WithAuth/WithAuth';
import WithFooter from './layouts/WithFooter/WithFooter';
import Login from './pages/Login';
import DashboardsViewer from './pages/DashboardsViewer';
import NotFound from './hoc/ErrorBoundary/NotFound/NotFound';

function App() {
  return (
    <Routes>
      <Route element={<WithFooter />}>
        <Route path='/' element={<Login />} />
        <Route
          path='/dashboards'
          element={
            <WithAuth>
              <DashboardsViewer />
            </WithAuth>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
