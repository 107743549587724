export enum IconTypeEnum {
  Error,
  Info,
  None,
}

export default interface NotificationType {
  shouldShow: boolean;
  iconType: IconTypeEnum;
  header: string;
  details: string;
  buttonText: string;
}
