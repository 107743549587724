import { useEffect, useState, useRef, useLayoutEffect } from 'react';

import { DimensionsType } from './types';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(): DimensionsType {
  const [windowDimensions, setWindowDimensions] = useState<DimensionsType>(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useComponentDimensions() {
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<DimensionsType>({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  return { targetRef, dimensions };
}
