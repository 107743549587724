import { Component } from 'react';

import { ErrorBoundaryPropsType, ErrorBoundaryStateType } from './types';
import NotFound from './NotFound/NotFound';

export default class ErrorBoundary extends Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props: ErrorBoundaryPropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />;
    }

    return this.props.children;
  }
}
