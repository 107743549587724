import { Amplify, Auth } from 'aws-amplify';
import { Dispatch, AnyAction } from 'redux';

import awsExport from '../../aws-exports';
import { login, setUserToken, setUserCredentials } from '../../state/User';
import { showNotification, IconTypeEnum } from '../../state/Notification';
import { SECONDS_TO_MILLI } from '../../consts';
import { CognitoChallengeEnum, CogniotoMFAChallengeType, CognitoErrorSourceEnum } from './types';

Amplify.configure(awsExport);

export function cognitoLogin(email: string, password: string) {
  return Auth.signIn(email, password);
}

export function cognitoSignUp(email: string, password: string) {
  return Auth.signUp({
    username: email,
    password,
  });
}

export function cognitoSendChallenge(cognitoUser: any, challenge: string, challengeType: CognitoChallengeEnum) {
  return Auth.confirmSignIn(cognitoUser, challenge, challengeType as CogniotoMFAChallengeType);
}

export function sendForgotPasswordEmail(email: string) {
  return Auth.forgotPassword(email);
}

export function cognitoResetPassword(email: string, oneTimeCode: string, password: string) {
  return Auth.forgotPasswordSubmit(email, oneTimeCode, password);
}

export function cognitoSetNewPassword(cognitoUser: any, newPassword: string) {
  return Auth.completeNewPassword(cognitoUser, newPassword);
}

export function cognitoSetopTOTP(cognitoUser: any) {
  return Auth.setupTOTP(cognitoUser);
}

export function cognitoVerifyTotpToken(cognitoUser: any, code: string) {
  return Auth.verifyTotpToken(cognitoUser, code);
}

export function buildQrCodeStr(cognitoUser: any, code: string) {
  const username = cognitoUser.username;
  const issuer = `${cognitoUser.pool.client.endpoint}${cognitoUser.pool.clientId}`;
  return `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`;
}

export function cognitoCurrentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

export function signInUser(cognitoUser: any, isRememberMe: boolean, dispatch: Dispatch<AnyAction>) {
  dispatch(
    login({
      isLoggedIn: true,
      awsUsername: cognitoUser.username,
    })
  );
  dispatch(
    setUserToken({
      jwtToken: cognitoUser.signInUserSession.idToken.jwtToken,
      isRememberMe: isRememberMe,
      expiryMilli: cognitoUser.signInUserSession.idToken.payload.exp * SECONDS_TO_MILLI,
      refreshToken: cognitoUser.signInUserSession.refreshToken.token,
    })
  );
  dispatch(
    setUserCredentials({
      email: cognitoUser.signInUserSession.idToken.payload.email,
      isRememberMe: isRememberMe,
    })
  );
}

function errorStrToTranslationKey(error: string) {
  return error.trim().replaceAll(' ', '-').toLowerCase();
}

export function showCognitoError(
  error: { log?: string; code?: string; name?: string; message?: string },
  source: CognitoErrorSourceEnum,
  dispatch: Dispatch<AnyAction>,
  translation: any
) {
  let translationKey = 'default-error';
  const message = error?.message ? error?.message : '';
  if (error.log) {
    translationKey = errorStrToTranslationKey(error.log);
    switch (translationKey) {
      case 'confirmation-code-cannot-be-empty':
        translationKey = `${translationKey}.${source}`;
        break;
    }
  } else if (error.code) {
    switch (error.code) {
      case 'InvalidParameterException':
        if (
          message.includes('Member must have length greater than or equal to 6') ||
          message.includes('Member must have length less than or equal to 6')
        ) {
          translationKey = 'code-must-be-6-numbers';
        } else {
          translationKey = 'password-cannot-be-empty';
        }
        break;
      case 'NotAuthorizedException':
        if (message.includes('Incorrect username or password')) {
          translationKey = 'password-incorrect';
        } else if (message.includes('Password attempts exceeded')) {
          translationKey = 'password-attempts-exceeded';
        } else if (message.includes('Invalid session for the user, session is expired')) {
          translationKey = 'session-expired';
        }
        break;
      case 'UserNotFoundException':
        translationKey = 'user-does-not-exist';
        break;
      case 'InvalidPasswordException':
        translationKey = 'password-does-not-meet-minimum-requirements';
        break;
      case 'LimitExceededException':
        translationKey = 'reset-password-attempts-exceeded';
        break;
      case 'CodeMismatchException':
        translationKey = 'invalid-verification-code';
        break;
      case 'EnableSoftwareTokenMFAException ':
        translationKey = 'wrong-setup-mfa-code';
        break;
    }
  }
  dispatch(
    showNotification({
      header: translation(`${translationKey}.header`),
      details: translation(`${translationKey}.details`),
      iconType: IconTypeEnum.Error,
      buttonText: translation(`${translationKey}.buttonText`),
    })
  );
}
