export const JWT_TOKEN_KEY = 'JWT_TOKEN_KEY';
export const EMAIL_KEY = 'EMAIL_KEY';
export const REMEMBER_ME_KEY = 'REMEMBER_ME_KEY';

export default interface UserType {
  isLoggedIn: boolean;
  email: string;
  awsUsername: string;
  jwtToken: string;
  refreshToken: string;
  isRememberMe: boolean;
  expiryMilli: number;
}

export interface LocalStorageJwtTokenType {
  jwtToken: string;
  expiryMilli: number;
}

export interface LocalStorageEmailType {
  email: string;
}

export interface LocalStorageIsRememberMeType {
  isRememberMe: boolean;
}
