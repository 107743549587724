import { useState } from 'react';

function getCookie(key: string, fallback = ''): string {
  const cookiePairs = document.cookie.split('; ');

  for (const cookiePair of cookiePairs) {
    const [cookieKey, cookieValue] = cookiePair.split('=');

    if (key === cookieKey) {
      return decodeURIComponent(cookieValue);
    }
  }
  return fallback;
}

function setCookie(key: string, value: any, maxAgeHours: number) {
  const maxAgeSeconds = maxAgeHours * 60 * 60;

  document.cookie = `${key}=${encodeURIComponent(value)}; max-age=${maxAgeSeconds}; secure; samesite=strict; `;
}

export function useCookie(key: string, fallback = '') {
  const cookieVal = getCookie(key, fallback);
  const [stateCookie, setStateCookie] = useState(cookieVal);

  const updateCookie = (value: any, maxAgeHours: number) => {
    setStateCookie(value);
    setCookie(key, value, maxAgeHours);
  };

  return [stateCookie, updateCookie] as const;
}
