import { createSlice } from '@reduxjs/toolkit';

import UserType, { JWT_TOKEN_KEY, EMAIL_KEY, REMEMBER_ME_KEY } from './types';

const initialState = {
  isLoggedIn: false,
  email: '',
  awsUsername: '',
  jwtToken: '',
  refreshToken: '',
  expiryMilli: 0,
} as UserType;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
      if (action.payload.isLoggedIn) {
        state.email = action.payload.email;
        state.awsUsername = action.payload.awsUsername;
      } else {
        return initialState;
      }
    },
    setUserToken(state, action) {
      state.jwtToken = action.payload.jwtToken;
      state.expiryMilli = action.payload.expiryMilli;
      state.refreshToken = action.payload.refreshToken;
      state.isRememberMe = action.payload.isRememberMe;
      if (action.payload.isRememberMe) {
        localStorage.setItem(
          JWT_TOKEN_KEY,
          JSON.stringify({
            jwtToken: action.payload.jwtToken,
            expiryMilli: action.payload.expiryMilli,
          })
        );
        localStorage.setItem(
          EMAIL_KEY,
          JSON.stringify({
            email: action.payload.email,
          })
        );
        localStorage.setItem(
          REMEMBER_ME_KEY,
          JSON.stringify({
            isRememberMe: action.payload.isRememberMe,
          })
        );
      }
    },
    setUserCredentials(state, action) {
      state.email = action.payload.email;

      if (action.payload.isRememberMe) {
        localStorage.setItem(
          EMAIL_KEY,
          JSON.stringify({
            email: action.payload.email,
          })
        );
      }
    },
    refreshToken(state, action) {
      state.jwtToken = action.payload.jwtToken;
      state.expiryMilli = action.payload.expiryMilli;
      state.refreshToken = action.payload.refreshToken;
    },
    logOut(state) {
      const isRememberMe = state.isRememberMe;
      if (!isRememberMe) {
        localStorage.removeItem(EMAIL_KEY);
      }
      localStorage.removeItem(JWT_TOKEN_KEY);
      return { ...initialState, isRememberMe };
    },
  },
});

export const { login, setUserToken, setUserCredentials, refreshToken, logOut } = userSlice.actions;

export default userSlice.reducer;
